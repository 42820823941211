@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    font-family: 'Poppins', sans-serif;
    background-color: #161616;

    
}
::-webkit-scrollbar {
    width: 6px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #000000;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(63, 63, 63);
}
.custom-font{
    font-family: 'Montserrat', sans-serif;
}
.highlight{
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: -webkit-linear-gradient( rgba(255, 255, 255, 0.15) 43.73%, rgba(0, 0, 0, 0.0375) 60.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
body{
    overscroll-behavior-y: contain;
}
.zeder{
    z-index: 10000000;
}